import { mainRoutes, generateAbsoluteRoutes } from '../main/routes';

export const supportV1RoutesRelative = {
  404: '/*',
  TRANSACTIONS: '/transactions-v1',
  TRANSACTIONS_DETAILS: '/transactions-v1/:id',
  SUPPORT_TICKETS: '/support-tickets-v1',
  SUPPORT_TICKETS_DETAILS: '/support-tickets-v1/:id',
  FLOMOJI: '/flomoji',
  SOUNDS: '/sounds',
  SOUNDS_DETAILS: '/sounds/:id',
  SMSDATA: '/sms-data',
  SMSDATA_DETAILS: '/sms-data/:id',
  CUSTOMER_ACTIVATION: '/customer-activation',
  TAXES: '/taxes',
  BANNED_CHATGPT_COUNTRIES: '/banned-chatgpt-countries',
  ID_APPLICATION: '/id-application',
  ID_APPLICATION_DETAILS: '/id-application/:id',
  SATS_BANNED_COUNTRIES: '/sats-banned-countries',
  SEND_SMS: '/send-sms',
  CONTACT_US: '/contact-us',
  CONTACT_US_DETAILS: '/contact-us/:id',
  CREDITS: '/credits',
  CREDITS_BALANCE_EMOJI: '/credits/balance-emoji',
  SPRAY_AMOUNT: '/credits/spray-amount',
  CONVERSION_RATES: '/credits/conversion-rates',
  CREDIT_LIMITS: '/credits/credit-limits',
  PAYOUT: '/payout',
  PAYOUT_DETAILS: '/payout/:id',
  PAYOUT_LIMITS: '/payout/limits',
  ADD_CREDITS: '/credits/add-credits',
  FEES: '/fees',
  STICKER_SETS: '/sticker-sets',
  STICKER_ITEMS: '/sticker-sets/:id',
  SETTINGS: '/settings',
};

export const supportV1Routes = generateAbsoluteRoutes(
  supportV1RoutesRelative,
  mainRoutes.SUPPORT_V1
);

export const supportV1Labels = {
  [supportV1Routes.TRANSACTIONS]: 'Transactions v1',
  [supportV1Routes.SUPPORT_TICKETS]: 'Support tickets v1',
  [supportV1Routes.FLOMOJI]: 'Flomoji',
  [supportV1Routes.SOUNDS]: 'Sounds',
  [supportV1Routes.SMSDATA]: 'SMSData',
  [supportV1Routes.CUSTOMER_ACTIVATION]: 'Customer activation',
  [supportV1Routes.TAXES]: 'Taxes',
  [supportV1Routes.BANNED_CHATGPT_COUNTRIES]: 'ChatGPT',
  [supportV1Routes.ID_APPLICATION]: 'ID Application',
  [supportV1Routes.SATS_BANNED_COUNTRIES]: 'Sats',
  [supportV1Routes.SEND_SMS]: 'Send SMS',
  [supportV1Routes.CONTACT_US]: 'Contact us',
  [supportV1Routes.CREDITS]: 'Credits',
  [supportV1Routes.CREDITS_BALANCE_EMOJI]: 'Balance emoji',
  [supportV1Routes.SPRAY_AMOUNT]: 'Spray amount',
  [supportV1Routes.CONVERSION_RATES]: 'Conversion rates',
  [supportV1Routes.CREDIT_LIMITS]: 'Credit limits',
  [supportV1Routes.PAYOUT]: 'Payout',
  [supportV1Routes.PAYOUT_LIMITS]: 'Payout limits',
  [supportV1Routes.ADD_CREDITS]: 'Add credits',
  [supportV1Routes.FEES]: 'Fees',
  [supportV1Routes.STICKER_SETS]: 'Sticker sets',
  [supportV1Routes.SETTINGS]: 'Settings',
};

export const breadcrumbLabels = {
  transactions: ['flom v1', supportV1Labels[supportV1Routes.TRANSACTIONS]],
  supportTickets: ['flom v1', supportV1Labels[supportV1Routes.SUPPORT_TICKETS]],
  flomoji: ['flom v1', supportV1Labels[supportV1Routes.FLOMOJI]],
  sounds: ['flom v1', supportV1Labels[supportV1Routes.SOUNDS]],
  smsData: ['flom v1', supportV1Labels[supportV1Routes.SMSDATA]],
  customerActivation: ['flom v1', supportV1Labels[supportV1Routes.CUSTOMER_ACTIVATION]],
  taxes: ['flom v1', supportV1Labels[supportV1Routes.TAXES]],
  chatGPT: ['flom v1', supportV1Labels[supportV1Routes.BANNED_CHATGPT_COUNTRIES]],
  IDApplication: ['flom v1', supportV1Labels[supportV1Routes.ID_APPLICATION]],
  sats: ['flom v1', supportV1Labels[supportV1Routes.SATS_BANNED_COUNTRIES]],
  sendSMS: ['flom v1', supportV1Labels[supportV1Routes.SEND_SMS]],
  contactUs: ['flom v1', supportV1Labels[supportV1Routes.CONTACT_US]],
  credits: ['flom v1', supportV1Labels[supportV1Routes.CREDITS]],
  balanceEmoji: [
    'flom v1',
    supportV1Labels[supportV1Routes.CREDITS],
    supportV1Labels[supportV1Routes.CREDITS_BALANCE_EMOJI],
  ],
  sprayAmount: [
    'flom v1',
    supportV1Labels[supportV1Routes.CREDITS],
    supportV1Labels[supportV1Routes.SPRAY_AMOUNT],
  ],
  conversionRates: [
    'flom v1',
    supportV1Labels[supportV1Routes.CREDITS],
    supportV1Labels[supportV1Routes.CONVERSION_RATES],
  ],
  addCredits: [
    'flom v1',
    supportV1Labels[supportV1Routes.CREDITS],
    supportV1Labels[supportV1Routes.ADD_CREDITS],
  ],
  creditLimits: [
    'flom v1',
    supportV1Labels[supportV1Routes.CREDITS],
    supportV1Labels[supportV1Routes.CREDIT_LIMITS],
  ],
  payout: ['flom v1', supportV1Labels[supportV1Routes.PAYOUT]],
  payoutLimits: [
    'flom v1',
    supportV1Labels[supportV1Routes.PAYOUT],
    supportV1Labels[supportV1Routes.PAYOUT_LIMITS],
  ],
  fees: ['flom v1', supportV1Labels[supportV1Routes.FEES]],
  stickerSets: ['flom v1', supportV1Labels[supportV1Routes.STICKER_SETS]],
  settings: ['flom v1', supportV1Labels[supportV1Routes.SETTINGS]],
};

export const breadcrumbLinks = {
  transactions: [undefined, supportV1Routes.TRANSACTIONS],
  supportTickets: [undefined, supportV1Routes.SUPPORT_TICKETS],
  flomoji: [undefined, supportV1Routes.FLOMOJI],
  sounds: [undefined, supportV1Routes.SOUNDS],
  smsData: [undefined, supportV1Routes.SMSDATA],
  customerActivation: [undefined, supportV1Routes.CUSTOMER_ACTIVATION],
  taxes: [undefined, supportV1Routes.TAXES],
  chatGPT: [undefined, supportV1Routes.BANNED_CHATGPT_COUNTRIES],
  IDApplication: [undefined, supportV1Routes.ID_APPLICATION],
  sats: [undefined, supportV1Routes.SATS_BANNED_COUNTRIES],
  sendSMS: [undefined, supportV1Routes.SEND_SMS],
  contactUs: [undefined, supportV1Routes.CONTACT_US],
  credits: [undefined, supportV1Routes.CREDITS],
  balanceEmoji: [undefined, supportV1Routes.CREDITS, supportV1Routes.CREDITS_BALANCE_EMOJI],
  sprayAmount: [undefined, supportV1Routes.CREDITS, supportV1Routes.SPRAY_AMOUNT],
  conversionRates: [undefined, supportV1Routes.CREDITS, supportV1Routes.CONVERSION_RATES],
  addCredits: [undefined, supportV1Routes.CREDITS, supportV1Routes.ADD_CREDITS],
  creditLimits: [undefined, supportV1Routes.CREDITS, supportV1Routes.CREDIT_LIMITS],
  payout: [undefined, supportV1Routes.PAYOUT],
  payoutLimits: [undefined, supportV1Routes.PAYOUT, supportV1Routes.PAYOUT_LIMITS],
  fees: [undefined, supportV1Routes.FEES],
  stickerSets: [undefined, supportV1Routes.STICKER_SETS],
  settings: [undefined, supportV1Routes.SETTINGS],
};

export const supportV1SubMenu = {
  credits: '/credits',
  payout: '/payout',
};
