import { roles } from 'features/hoc';

export const supportV1RoleRules = {
  menu: {
    min: roles.admin.value,
    include: [roles.supportTicket.value, roles.stickerManager.value],
  },
  supportTickets: { min: roles.admin.value, include: [roles.supportTicket.value] },
  transactions: { min: roles.admin.value },
  other: { min: roles.admin.value },
  flomoji: { min: roles.admin.value },
  sounds: { min: roles.admin.value },
  smsData: { min: roles.admin.value },
  customerActivation: { min: roles.admin.value },
  taxes: { min: roles.admin.value },
  chatGPT: { min: roles.admin.value },
  sats: { min: roles.admin.value },
  sendSMS: { min: roles.admin.value },
  contactUs: { min: roles.admin.value },
  credits: { min: roles.admin.value },
  balanceEmoji: { min: roles.admin.value },
  sprayAmount: { min: roles.admin.value },
  conversionRates: { min: roles.admin.value },
  creditLimits: { min: roles.admin.value },
  payout: { min: roles.admin.value },
  payoutLimits: { min: roles.admin.value },
  addCredits: { min: roles.superAdmin.value },
  fees: { min: roles.admin.value },
  stickerSets: { min: roles.admin.value, include: [roles.stickerManager.value] },
  settings: { min: roles.admin.value },
  IDApplication: { min: roles.admin.value },
};
