import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { Typography, Avatar, Radio, message, Switch, Select } from 'antd';
import { createFileUrlV1 } from 'core/api';
import { LoadingFlom, FlagFromPhoneNumber } from 'features/components';
import { SetNavigation } from 'features/layout';
import { breadcrumbLabels, breadcrumbLinks, usersRoutes } from '../../../routes';
import {
  getUserById,
  postUserFeaturedStatusAll,
  patchUserBlockStatus,
  setInternationalStatus,
} from '../../api/usersApi';
import styles from './UserDetails.module.scss';
import { arraysHaveSameElements, countries } from 'core/helpers';
import { DeviceType, LoggedStatus } from '../../components';

function UserDetails() {
  const params = useParams();
  const location = useLocation();
  const queryClient = useQueryClient();

  const [blockedProducts, setBlockedProducts] = useState();
  const [featuredAllUser, setFeaturedAllUser] = useState();
  const [internationalUser, setInternationalUser] = useState(false);

  const user = useQuery(['User v2', { id: params.id }], getUserById);

  const featuredUserAllMutation = useMutation(postUserFeaturedStatusAll, {
    onMutate: () => {
      message.loading({
        content: 'Updating featured user status.',
        key: 'featuredUserAll',
        duration: 100,
      });
    },
    onSuccess: (data) => {
      setFeaturedAllUser(arraysHaveSameElements(user.data.user.featured?.types, [1, 2, 3, 4, 5]));
      queryClient.invalidateQueries(['User v2', { id: params.id }]);
      message.success({
        content: 'User featured status update complete.',
        key: 'featuredUserAll',
        duration: 3,
      });
    },
    onError: () => {
      setFeaturedAllUser(arraysHaveSameElements(user.data.user.featured?.types, [1, 2, 3, 4, 5]));
      message.error({
        content: 'Feature user update failed!',
        key: 'featuredUserAll',
        duration: 5,
      });
    },
  });

  const patchUserBlockedStatus = useMutation(patchUserBlockStatus, {
    onMutate: () => {
      message.loading({
        content: 'Updating users block status.',
        key: 'blockedStatus',
        duration: 100,
      });
    },
    onSuccess: () => {
      message.success({
        content: 'Block status update complete.',
        key: 'blockedStatus',
        duration: 3,
      });
    },
    onError: () => {
      message.error({ content: 'Block status update failed!', key: 'blockedStatus', duration: 5 });
    },
  });

  const changeInternationalStatusMutation = useMutation(
    ({ userId, internationalUser }) => setInternationalStatus(userId, internationalUser),
    {
      onMutate: ({ internationalUser }) => {
        let text = 'Changing merchant status changed to local.';
        if (internationalUser) {
          text = 'Changing merchant status changed to international.';
        }

        message.loading({
          key: 'internationalStatus',
          content: text,
          duration: 30,
        });
      },
      onSuccess: (response, { internationalUser }) => {
        let text = 'User merchant status changed to local.';
        if (internationalUser) {
          text = 'User merchant status changed to international.';
        }

        message.success({ key: 'internationalStatus', content: text, duration: 5 });
        queryClient.invalidateQueries(['User', { id: params.id }]);
      },
      onError: () => {
        message.error({
          key: 'internationalStatus',
          content: 'There was an error while changing the status. Please try again later.',
          duration: 5,
        });
        queryClient.invalidateQueries(['User', { id: params.id }]);
      },
    }
  );

  useEffect(() => {
    if (user.data) {
      setBlockedProducts(user.data.user.blockedProductsLinked);
      setFeaturedAllUser(arraysHaveSameElements(user.data.user.featured?.types, [1, 2, 3, 4, 5]));
      setInternationalUser(user.data.user.internationalUser);
    }
  }, [user.data]);

  const productDetailsLink = location.pathname;
  if (user.isLoading) {
    const label = location.state?.username ? location.state.username : params.id;
    return (
      <SetNavigation
        breadcrumbsLabels={[...breadcrumbLabels.userManagement, label]}
        breadcrumbsLinks={[...breadcrumbLinks.userManagement, productDetailsLink]}
        breadcrumbsStates={[{ prevSearch: location.state?.prevSearch }]}
        siderSelectedKey={usersRoutes.USER_MANAGEMENT}
      >
        <LoadingFlom />
      </SetNavigation>
    );
  }

  if (user.isError) {
    return (
      <SetNavigation
        breadcrumbsLabels={[...breadcrumbLabels.userManagement, params.id]}
        breadcrumbsLinks={[...breadcrumbLinks.userManagement, productDetailsLink]}
        breadcrumbsStates={[{ prevSearch: location.state?.prevSearch }]}
        siderSelectedKey={usersRoutes.USER_MANAGEMENT}
      >
        <Typography.Text>Unable to find product!</Typography.Text>
      </SetNavigation>
    );
  }

  const onBlockedChangeHandler = (event) => {
    const value = event.target.value;
    setBlockedProducts(value);
    patchUserBlockedStatus.mutate({ userId: user.data.user.id, status: value });
  };

  const onFeaturedAllChangeHandler = (value) => {
    setFeaturedAllUser(value);
    featuredUserAllMutation.mutate({ userId: user.data.user.id, value });
  };

  const onFeaturedCountryChangeHandler = (value) => {
    featuredUserAllMutation.mutate({ userId: user.data.user.id, value });
  };

  const onInternationalUserChangeHandler = (value) => {
    setInternationalUser(value);
    changeInternationalStatusMutation.mutate({
      userId: user.data.user.id,
      internationalUser: value,
    });
  };

  return (
    <SetNavigation
      breadcrumbsLinks={[...breadcrumbLinks.userManagement, location.pathname]}
      breadcrumbsLabels={[...breadcrumbLabels.userManagement, user.data.user.username]}
      breadcrumbsStates={[{ prevSearch: location.state?.prevSearch }]}
      siderSelectedKey={usersRoutes.USER_MANAGEMENT}
    >
      <div className={styles.container}>
        {user.data.user.avatar && (
          <Avatar
            className={styles.avatar}
            size={84}
            src={createFileUrlV1(user.data.user.avatar.picture.nameOnServer)}
          />
        )}

        <Typography.Title level={3} className={styles.username} strong>
          {user.data.user.username}
        </Typography.Title>

        <Typography.Text className={styles.itemTitle} strong>
          Phone number:
        </Typography.Text>
        <div>
          <FlagFromPhoneNumber phoneNumber={user.data.user.phoneNumber} />
          <Typography.Text className={styles.itemContent}>
            {user.data.user.phoneNumber}
          </Typography.Text>
        </div>

        {user.data.user.firstName && (
          <>
            <Typography.Text className={styles.itemTitle} strong>
              First name:
            </Typography.Text>
            <Typography.Text className={styles.itemContent}>
              {user.data.user.firstName}
            </Typography.Text>
          </>
        )}

        {user.data.user.lastName && (
          <>
            <Typography.Text className={styles.itemTitle} strong>
              Last name:
            </Typography.Text>
            <Typography.Text className={styles.itemContent}>
              {user.data.user.lastName}
            </Typography.Text>
          </>
        )}

        {user.data.user.email && (
          <>
            <Typography.Text className={styles.itemTitle} strong>
              Email:
            </Typography.Text>
            <Typography.Text className={styles.itemContent}>{user.data.user.email}</Typography.Text>
          </>
        )}

        <Typography.Text className={styles.itemTitle} strong>
          Block status:
        </Typography.Text>
        <Radio.Group
          className={styles.radioGroup}
          value={blockedProducts}
          onChange={onBlockedChangeHandler}
        >
          <Radio value="blocked">Blocked</Radio>
          <Radio value="active">Active</Radio>
        </Radio.Group>

        <Typography.Text className={styles.itemTitle} strong>
          Featured user:
        </Typography.Text>
        <Switch
          {...(featuredAllUser && { checked: true })}
          className={styles.featuredSwitch}
          onChange={onFeaturedAllChangeHandler}
          loading={featuredUserAllMutation.isLoading}
        />
        <Typography.Text strong className={[styles.title, styles.alignCenter].join(' ')}>
          Featured for country:
        </Typography.Text>
        <div className={styles.featuredCountryContainer}>
          <Select
            className={styles.selectContainer}
            placeholder="Select country"
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
            value={user.data.user?.featured?.countryCode || 'default'}
            onChange={onFeaturedCountryChangeHandler}
            disabled={!featuredAllUser}
          >
            {countries.map((country) => (
              <Select.Option key={country.code} value={country.code}>
                {country.name}
              </Select.Option>
            ))}
            <Select.Option key="default" value="default">
              default
            </Select.Option>
          </Select>
        </div>
        <Typography.Text className={styles.itemTitle} strong>
          International user:
        </Typography.Text>
        <Switch
          {...(internationalUser && { checked: true })}
          className={styles.featuredSwitch}
          onChange={onInternationalUserChangeHandler}
          loading={changeInternationalStatusMutation.isLoading}
        />

        <Typography.Text className={styles.itemTitle} strong>
          User created:
        </Typography.Text>
        <Typography.Text className={styles.itemContent}>
          {dayjs(user.data.mobileData.created).format('DD/MM/YYYY - H:mm:ss')}
        </Typography.Text>

        {user.data.mobileData.created !== user.data.mobileData.modified && (
          <>
            <Typography.Text className={styles.itemTitle} strong>
              User modified:
            </Typography.Text>
            <Typography.Text className={styles.itemContent}>
              {dayjs(user.data.mobileData.modified).format('DD/MM/YYYY - H:mm:ss')}
            </Typography.Text>
          </>
        )}

        {user.data.mobileData.lastLogin && (
          <>
            <Typography.Text className={styles.itemTitle} strong>
              User last login:
            </Typography.Text>
            <Typography.Text className={styles.itemContent}>
              {dayjs(user.data.mobileData.lastLogin).format('DD/MM/YYYY - H:mm:ss')}
            </Typography.Text>
          </>
        )}

        {!!user.data.mobileData.loginCount && (
          <>
            <Typography.Text className={styles.itemTitle} strong>
              User login count:
            </Typography.Text>
            <Typography.Text className={styles.itemContent}>
              {user.data.mobileData.loginCount}
            </Typography.Text>
          </>
        )}

        <Typography.Text className={styles.itemTitle} strong>
          Name:{' '}
        </Typography.Text>
        <Typography.Text className={styles.itemContent}>
          {user.data.mobileData.name}
        </Typography.Text>

        <Typography.Text className={styles.itemTitle} strong>
          Device:{' '}
        </Typography.Text>
        <div>
          <DeviceType deviceType={user.data.user.deviceType} />
        </div>

        <Typography.Text className={styles.itemTitle} strong>
          Short description:{' '}
        </Typography.Text>
        <Typography.Text className={styles.itemContent}>
          {user.data.mobileData.description}
        </Typography.Text>

        <Typography.Text className={styles.itemTitle} strong>
          Bank account list:
        </Typography.Text>
        <Typography.Text className={styles.itemContent}>
          {user.data.mobileData.bankAccounts.map((acc) => {
            return (
              <Typography.Text key={acc.merchantCode} className={styles.bankList}>
                {acc.name}, {acc.merchantCode}
              </Typography.Text>
            );
          })}
        </Typography.Text>

        <Typography.Text className={styles.itemTitle} strong>
          Is user creator:
        </Typography.Text>
        <Typography.Text className={styles.itemContent}>
          {user.data.mobileData.isCreator.toString()}
        </Typography.Text>

        <Typography.Text className={styles.itemTitle} strong>
          Is user seller:
        </Typography.Text>
        <Typography.Text className={styles.itemContent}>
          {user.data.mobileData.isSeller.toString()}
        </Typography.Text>

        <Typography.Text className={styles.itemTitle} strong>
          Is user deleted:
        </Typography.Text>
        <Typography.Text className={styles.itemContent}>
          {user.data.mobileData.isDeleted.value.toString()}
        </Typography.Text>

        {user.data.mobileData.isDeleted.value && (
          <>
            <Typography.Text className={styles.itemTitle} strong>
              Delete time:
            </Typography.Text>
            <Typography.Text className={styles.itemContent}>
              {dayjs(user.data.mobileData.isDeleted.created).format('DD/MM/YYYY - H:mm:ss')}
            </Typography.Text>
          </>
        )}

        <Typography.Text className={styles.itemTitle} strong>
          Log in status:
        </Typography.Text>
        <div>
          <LoggedStatus hasLoggedIn={user.data?.user.hasLoggedIn} />
        </div>
      </div>
    </SetNavigation>
  );
}

export default UserDetails;
