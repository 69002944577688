import { useCheckRole } from 'features/hoc';
import { supportV1RoleRules } from './supportRoleRulesV1';

export function useShowSupportV1() {
  const showSupport = {
    menu: useCheckRole(supportV1RoleRules.menu),
    transactions: useCheckRole(supportV1RoleRules.transactions),
    supportTickets: useCheckRole(supportV1RoleRules.supportTickets),
    other: useCheckRole(supportV1RoleRules.other),
    flomoji: useCheckRole(supportV1RoleRules.flomoji),
    sounds: useCheckRole(supportV1RoleRules.sounds),
    smsData: useCheckRole(supportV1RoleRules.smsData),
    customerActivation: useCheckRole(supportV1RoleRules.customerActivation),
    taxes: useCheckRole(supportV1RoleRules.taxes),
    chatGPT: useCheckRole(supportV1RoleRules.chatGPT),
    sats: useCheckRole(supportV1RoleRules.sats),
    sendSMS: useCheckRole(supportV1RoleRules.sendSMS),
    contactUs: useCheckRole(supportV1RoleRules.contactUs),
    credits: useCheckRole(supportV1RoleRules.credits),
    balanceEmoji: useCheckRole(supportV1RoleRules.balanceEmoji),
    sprayAmount: useCheckRole(supportV1RoleRules.sprayAmount),
    conversionRates: useCheckRole(supportV1RoleRules.conversionRates),
    creditLimits: useCheckRole(supportV1RoleRules.creditLimits),
    payout: useCheckRole(supportV1RoleRules.payout),
    payoutLimits: useCheckRole(supportV1RoleRules.payoutLimits),
    addCredits: useCheckRole(supportV1RoleRules.addCredits),
    fees: useCheckRole(supportV1RoleRules.fees),
    stickerSets: useCheckRole(supportV1RoleRules.stickerSets),
    settings: useCheckRole(supportV1RoleRules.settings),
    IDApplication: useCheckRole(supportV1RoleRules.IDApplication),
  };

  return showSupport;
}
